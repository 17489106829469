import React from "react";

import ReactMarkdown from "react-markdown";
import { Container } from "src/components/Container";
import { LinkList } from "src/components/LinkList";
import { PageWrapper } from "src/components/PageWrapper";
import { PathEnum } from "src/enums/PathEnum";

import * as styles from "./BlogBlock.module.scss";

interface BlogProps {
  title: string;
  content: string;
}

export const Blog: React.FC<BlogProps> = ({
  title,
  content,
  image,
  blog_categories,
}) => {
  return (
    <PageWrapper>
      <Container>
        <div className={styles.parent}>
          <div className={styles.leftcolumn}>
            <img src={image.localFile.publicURL} alt="image1" />
            <h1>{title}</h1>
            <ReactMarkdown>{content}</ReactMarkdown>

            <div className={styles.tags}>
              <LinkList
                items={blog_categories.map((blogCategory) => ({
                  text: blogCategory.name,
                  href: `${PathEnum.MakeItLoud}?category=${blogCategory.name}`,
                  active: false,
                }))}
                linkProps={{
                  color: "black",
                  hoverFill: "black",
                  size: "s",
                }}
                flexWrap
              />
            </div>
          </div>

          {/* <div className={styles.rightcolumn}>
            <b>Материалы по теме</b>
            <p />
            <a href="URL">UX-ислледования</a>
            <p />
            <a href="URL">Как мы искали аналитиков в нашу UXLAB</a>
          </div> */}
        </div>
      </Container>
    </PageWrapper>
  );
};
