import React from "react";

import { graphql } from "gatsby";
import { Blog } from "src/layouts/Blog";

const BlogPage = ({ data }) => {
  return <Blog {...data.strapiBlogs} />;
};

export const query = graphql`
  query ($id: String) {
    strapiBlogs(id: { eq: $id }) {
      title
      content
      slug
      image {
        localFile {
          publicURL
        }
      }
      blog_categories {
        name
      }
    }
  }
`;

export default BlogPage;
